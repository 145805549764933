const ManteReducer  =(state , action) =>{

    switch (action?.type) {
        
        case 'mante':
            return{
                ...state,
                status:action?.payload?.status,
                fechaaper:action?.payload?.fechaaper,
                fechacier:action?.payload?.fechacier
            }
    
        default:
            break
    }

}
export default ManteReducer