import React, { useEffect, useReducer, Suspense,lazy, useState} from 'react';
import './App.css';
import {BrowserRouter as Router,Route ,Switch,Redirect , HashRouter} from 'react-router-dom';
import {AppContext} from './context/AppContext';
import {CarritoContext} from './context/CarritoContext';
import {ManteContext} from './context/ManteContext';
import AppReducer from './reducers/AppReducer';
import CarritoReducer from './reducers/CarritoReducer'
import ManteReducer from './reducers/ManteReducer'
import './assets/css/Components.css'
import Axios from 'axios';
import {url} from './data/url'

const NotFound =lazy(()=>import('./views/NotFound'))
const Carrito =lazy(()=>import('./views/Carrito'))
const Productos =lazy(()=>import('./views/Productos'))
const Categorias =lazy(()=>import('./views/Categorias'))
const NavBar2 =lazy(()=>import('./components/AppBar/NavBar2'))
const NavBarFF =lazy(()=>import('./components/AppBar/NavBarFF'))
const Footer =lazy(()=>import('./components/Footer/footeremilima'))
const Login2 =lazy(()=>import('./views/Login2'))
const Registro2 =lazy(()=>import('./views/Registro2'))
const DetalleEnvio =lazy(()=>import('./components/detalle envio/DetalleEnvio'))
const ProductosDetalle =lazy(()=>import('./components/Productos/ProductoDetalle'))
const Politica =lazy(()=>import('./views/Politica'))
const SelectDrop =lazy(()=>import('./components/detalle envio/SelectDrop'))
const PerfilUsuario =lazy(()=>import('./views/PerfilUsuario'))
const ActualizarUsuario =lazy(()=>import('./views/ActualizarUsuario'))
const Maintenance =lazy(()=>import('./views/Maintenance'))
const Compras =lazy(()=>import('./views/Compras'))
const ForgotPassword=lazy(()=>import('./views/ForgotPassword'))
const EmailSuccess=lazy(()=>import('./components/Email/SuccessEmail'))
const EmailError=lazy(()=>import('./components/Email/ErrorEmail'))

const App = () => {

  const init1 =()=>{
    return JSON.parse(localStorage.getItem('carrito'))||
    {
      carro:[]
    }
  }

  const init = () =>{
     return JSON.parse(localStorage.getItem('user')) || 
    {
      isLogged: false 
    }   
  }

  const [value, dispatch] = useReducer(AppReducer, {}, init)

  useEffect(() => {
    localStorage.setItem('user',JSON.stringify(value))
  }, [value])

 
  const [carrito, dispatch1] = useReducer(CarritoReducer, {},init1 )

  useEffect(() => {
    localStorage.setItem('carrito',JSON.stringify(carrito))
  }, [carrito])
  // console.log(carrito.carro)
  
  const [mante, setmante] = useState({
    status:"2"
  })
  
  const [mantenimiento, dispatch2] = useReducer(ManteReducer, mante)  

  useEffect(() => {
    Axios
    .get(`${url}admin/mantenimiento`)
    .then(res =>{
      console.log(res)
      dispatch2({
        type:'mante',
        payload:{
          status:res.data.config_tienda.condicion_abierto,
          fechaaper:res.data.config_tienda.fecha_apertura,
          fechacier:res.data.config_tienda.fecha_cierre,
        }
      })

    })
    }, [mante.status])

 

 

  const [anchorEl1, setAnchorEl1] = React.useState(null);

    const handleClick = ({currentTarget}) => {
      console.log(currentTarget)
      setAnchorEl1(currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl1(null);
    };
  
    const open1 = Boolean(anchorEl1);
    
    const id = open1 ? 'simple-popover' : undefined;
    
    console.log(anchorEl1)

    const pp="p"
  return (
    <>
    {
      mantenimiento.status === "1" &&
      <AppContext.Provider value={{value,dispatch}}>

      <CarritoContext.Provider value={{carrito,dispatch1}}> 
      <ManteContext.Provider value={{mantenimiento,dispatch2}}>
      <Router>
      <Suspense 
      fallback = { 
         <div className="loader-container">
                  <div className="loader"></div>
              </div>
      }
      
      >
      
       <NavBar2></NavBar2>
      <NavBarFF 
      anchorEl1={anchorEl1} 
      setAnchorEl1={setAnchorEl1} 
      handleClick={handleClick} 
      handleClose={handleClose}
      open1={open1}
      id={id}></NavBarFF> 

      <Switch>
        
        <Route  path="/login" exact component={Login2} ></Route>
        <Route  path="/registro" exact component={Registro2} ></Route>
        <Route  path="/categorias" exact component={Categorias} ></Route>
        <Route  path="/categorias/:id" exact component={Productos}></Route>
        <Route  path="/producto/:id" exact  ><ProductosDetalle></ProductosDetalle></Route>
        <Route  path="/politicaprivacidad" exact component={Politica}></Route>
        <Route  path="/carrito" exact component={Carrito}></Route>
        <Route  path="/prueba" exact component={SelectDrop}></Route>
        <Route  path="/detalleenvio" exact component={DetalleEnvio}></Route>
        <Route  path="/notfound" exact component={NotFound}></Route>
        <Route  path="/profile" exact component={PerfilUsuario}></Route>
        <Route  path="/updateprofile" exact component={ActualizarUsuario}></Route>
        <Route  path="/forgotpassword" exact component={ForgotPassword}></Route>
        <Route  path="/emailsuccess" exact component={EmailSuccess}></Route>
        <Route  path="/emailerror" exact component={EmailError}></Route>

        <Route  path="/miscompras" exact component={Compras}></Route>
        <Route  path="/" exact component={Categorias}></Route>
        <Redirect to="/" ></Redirect>
      </Switch>
      
    <Footer></Footer>  
     </Suspense> 
    </Router>
    </ManteContext.Provider>
    </CarritoContext.Provider>
    </AppContext.Provider>
  }
  {
    mantenimiento.status === "0" &&
<AppContext.Provider value={{value,dispatch}}>

<CarritoContext.Provider value={{carrito,dispatch1}}> 
<ManteContext.Provider value={{mantenimiento,dispatch2}}>
  
    <Router>
      <Suspense 
     fallback = { 
      <div className="loader-container">
                <div className="loader"></div>
            </div>
    }>
      <Switch>
      <Route  path="/maintenance" exact><Maintenance fecha="1"></Maintenance></Route>
      <Redirect to="/maintenance" ></Redirect>
      </Switch>
      </Suspense> 
    </Router>
    </ManteContext.Provider>
     </CarritoContext.Provider>
     </AppContext.Provider>
    }
    
  
    </>
  )
}

export default App
