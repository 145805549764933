
const initialState = {
    nombres: '',
    email: '',
    proveedor: '',
    proveedor_id: '',
    token: '',
    avatar: '',
    isLogged: false
}

const AppReducer = (state = initialState, action) => {
    
    switch (action?.type) {
        case 'login':
            return{
                ...state,
                name:action?.payload.name,
                email:action?.payload.email,
                token:action?.payload.token,
                isLogged: true
                
            }
        case 'logout':
            return{
                
                isLogged:false
                
            }
        
      
            
            
        default:
            break;
    }
}

export default AppReducer
